<template>
  <div>
    <a-table
      :dataSource="table_data"
      :columns="table_columns"
      :pagination="pagination_props"
      @change="changeCurrentPage"
      :loading="loading_data"
      :scroll="{ x: 1600 }"
    >
      <!-- Modal de filtro de input -->
      <template #filterDropdown="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-form
            name="form"
            layout="vertical"
            ref="formRef"
            :model="filter_values"
            :rules="rules"
          >
            <a-form-item has-feedback :name="column.dataIndex">
              <a-input
                v-model:value="filter_values[column.key]"
                @keyup="formatRuts"
                :maxlength="column.dataIndex == 'companyRut' ? 12 : 40"
                :placeholder="`Buscar por ${column.title}`"
                @pressEnter="
                  handleSearch(
                    column.dataIndex,
                    filter_values[column.key],
                    confirm
                  )
                "
                style="width: 188px; margin-bottom: 8px; display: block"
              />
            </a-form-item>
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  filter_values[column.key],
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </a-form>
        </div>
      </template>

      <!-- Modal de filtro de input -->
      <template #filterCompanies="{ column, confirm, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-select
            v-model:value="selected_companies"
            :options="companies"
            mode="multiple"
            show-search
            :filterOption="true"
            optionFilterProp="label"
            placeholder="Buscar por Razon social"
            style="width: 100%"
          >
            <template #dropdownRender="{ menuNode: menu }">
              <v-nodes :vnodes="menu" />
              <div
                style="
                  border-top: 1px solid #f0f0f0;
                  display: flex;
                  justify-content: space-between;
                "
                class="p-2"
                @mousedown="(e) => e.preventDefault()"
              >
                <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    handleSearch(
                      column.dataIndex,
                      selected_companies.join(','),
                      confirm
                    )
                  "
                >
                  Buscar
                </a-button>

                <a-button
                  size="small"
                  style="width: 90px"
                  @click="handleColumnReset(column.dataIndex, clearFilters)"
                >
                  Limpiar
                </a-button>
              </div>
            </template>
          </a-select>
          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  selected_companies.join(','),
                  confirm
                )
              "
            >
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <!-- Filtros para estados -->

      <template #filterStates="{ confirm, column, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-checkbox-group v-model:value="states_selection">
            <a-row>
              <a-col
                :span="24"
                v-for="(state, index) in states_options"
                :key="index"
              >
                <a-checkbox :value="state.value">{{ state.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>

          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  states_selection.join(','),
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <!-- Tag de los estados -->
      <template #tag="{ text: tag }">
        <tag :stateId="tag" />
      </template>

      <!-- Icono de filtrado de lupa -->
      <template #filterIcon="{ column }">
        <search-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <!-- Icono de filtrado para seleccion por checkbox -->
      <template #filterIconState="{ column }">
        <filter-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <!-- Tag de los estados -->
      <template #details="{ text }">
        <a-tooltip :color="'#05045D'">
          <template #title>Ver más</template>
          <router-link
            :to="{
              name:
                ticket_type == 'Alta'
                  ? 'AltaMasivaDetail'
                  : ticket_type == 'Baja'
                  ? 'BajaMasivaDetail'
                  : ticket_type == 'Alta cargas'
                  ? 'AltaCargasMasivaDetalle'
                  : 'DetalleModificacionMasiva',
              params: { id: text }
            }"
          >
            <eye-outlined />
          </router-link>
        </a-tooltip>
      </template>
    </a-table>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  SearchOutlined,
  FilterOutlined,
  EyeOutlined
} from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import {
  FETCH_TABLE_MASIVE_TICKETS,
  GETTER_GROUPS_TICKET_TABLE_ROWS,
  GETTER_GROUPS_TICKET_TABLE_TOTAL_PAGES,
  GETTER_GROUPS_TICKET_TABLE_ITEMS_PER_PAGE,
  GETTER_GROUPS_TICKET_TABLE_CURRENT_PAGE,
  GET_ALL_COMPANIES,
  FETCH_ALL_COMPANIES
} from '../../store/types';
import rutHelper from '../../utils/Ruts';
import tag from '../massive-tags.vue';

export default {
  name: 'MasiveTable',
  components: {
    'search-outlined': SearchOutlined,
    'filter-outlined': FilterOutlined,
    'eye-outlined': EyeOutlined,
    tag,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    }
  },
  props: {
    type: String,
    dates: Object,
  },
  setup(props) {
    // * Tipo de ticket
    const ticket_type = computed(() => props.type);

    // * VUEX
    const store = useStore();

    // * Vue router ### (1) ###
    const route = useRoute();
    const router = useRouter();
    const formRef = ref();
    const validError = ref();

    // * Estar atento a cualquier cambio de la url ### (2) ###
    const loading_data = ref(false); // efecto de carga de la tabla cuando encuentre la data
    watchEffect(async () => {
      loading_data.value = true;

      try {
        await store.dispatch(
          FETCH_TABLE_MASIVE_TICKETS,
          `type=${props.type}&${
            route.fullPath.split('?')[1] != undefined
              ? route.fullPath.split('?')[1]
              : ''
          }`
        );
      } catch (error) {
        console.log(error);
      }

      loading_data.value = false;
    });

    // * Redireccion al momento de hacer un filtro ### (3) ###
    const filter_values = ref({}); // Objeto que guarda los valores de los filtros
    const search_value = ref({}); // Objeto que guarda los valores de los cuales se hace la busqueda por filtro

    const deleteParamForRedirection = (key) => {
      delete search_value.value[key];
    };

    const addParamForRedirection = (key, param) => {
      if (key == 'companyRut' || key == 'employeeRut') {
        search_value.value[key] = rutHelper.normalizeRut(param);
      } else {
        search_value.value[key] = param;
      }

      if (key != 'page') {
        search_value.value['page'] = 1;
      }
    };

    // Cuando se use un filtro de hace una redireccion de la pagina
    const filterRedirectionNewParam = () => {
      formRef.value
        .validate()
        .then(() => {
          router.replace({
            name:
              props.type === 'Alta'
                ? 'Altas'
                : props.type === 'Baja'
                ? 'Bajas'
                : props.type === 'Alta cargas'
                ? 'AltasCargas'
                : 'Modificaciones',
            query: search_value.value,
            hash: '#masive'
          });
        })
        .catch((error) => {
          console.log('error', error);
          validError.value = error;
        });
    };
    const formatRuts = () => {
      if (filter_values.value['companyRut']) {
        filter_values.value['companyRut'] = rutHelper.formatRut(
          filter_values.value['companyRut']
        );
      }
    };

    // Redireccion reseteando o sacando un parametro
    const filterRedirectionResetColumn = (key) => {
      delete search_value.value[key];
      //Modificar luego de entender
      router.replace({
        name:
        props.type === 'Alta'
          ? 'Altas'
          : props.type === 'Baja'
          ? 'Bajas'
          : props.type === 'Alta cargas'
          ? 'AltasCargas'
          : 'Modificaciones',
        query: search_value.value,
        hash: '#masive'
      });
    };

    // * Filtros de las columnas con las rutas cambiantes ### (5) ###
    // Tabla
    const table_columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Holding',
        dataIndex: 'holding',
        key: 'holding',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Subholding',
        dataIndex: 'subHolding',
        key: 'subHolding',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Razón social',
        dataIndex: 'companyId',
        key: 'companyId',
        slots: {
          filterDropdown: 'filterCompanies',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'RUT empresa',
        dataIndex: 'companyRut',
        key: 'companyRut',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      ...(props.type !== 'Modificacion'
        ? [
            {
              title: 'Creados',
              dataIndex: 'ticketStatesCount.created',
              key: 'ticketStatesCount.created'
            },
            {
              title: 'En bewell',
              dataIndex: 'ticketStatesCount.reviewBewell',
              key: 'ticketStatesCount.reviewBewell'
            },
            {
              title: 'En aseguradora',
              dataIndex: 'ticketStatesCount.reviewInsuranceCompany',
              key: 'ticketStatesCount.reviewInsuranceCompany'
            },
            {
              title: 'Cancelados',
              dataIndex: 'ticketStatesCount.canceled',
              key: 'ticketStatesCount.canceled'
            },
            {
              title: 'Ejecutados',
              dataIndex: 'ticketStatesCount.executed',
              key: 'ticketStatesCount.executed'
            }
          ]
        : [
            {
              title: 'Estado',
              dataIndex: 'ticketGroupStateId',
              key: 'ticketGroupStateId',
              slots: {
                customRender: 'tag'
              }
            }
          ]),
      {
        title: 'Opciones',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'details'
        }
      }
    ];

    const table_data = computed(() => {
      return store.getters[GETTER_GROUPS_TICKET_TABLE_ROWS].map((obj) => {
        return {
          id: obj.id,
          key: obj.id,
          holding: obj.holding,
          companyId: obj.company.name,
          companyRut: rutHelper.formatRut(obj.company.rut),
          subHolding: obj.subHolding,
          ticketStatesCount: obj.ticketStatesCount,
          ticketCount: obj.count,
          ticketGroupStateId: obj.ticketGroupStateId
        };
      });
    });

    const total = computed(() => {
      return store.getters[GETTER_GROUPS_TICKET_TABLE_TOTAL_PAGES];
    });

    // Items por pagina
    const items_per_page = computed(() => {
      return store.getters[GETTER_GROUPS_TICKET_TABLE_ITEMS_PER_PAGE];
    });

    // Pagina en la que nos encontramos
    const current_page = computed(() => {
      return store.getters[GETTER_GROUPS_TICKET_TABLE_CURRENT_PAGE];
    });

    const pagination_props = reactive({
      total,
      pageSize: items_per_page,
      current: current_page
    });

    const changeCurrentPage = (page) => {
      addParamForRedirection('page', page.current);
      filterRedirectionNewParam();
    };

    // Seleccion de estados
    const states_selection = ref([]);
    const states_options = ref([
      {
        label: 'Creado',
        value: 1
      },
      {
        label: 'En revisión corredora',
        value: 7
      },
      {
        label: 'En revisión aseguradora',
        value: 8
      },
      {
        label: 'Ejecutado',
        value: 3
      },
      {
        label: 'Cancelado',
        value: 4
      }
    ]);

    // Busqueda por columna
    const handleSearch = (column, value, confirm) => {
      if (value != '' && value != undefined) {
        // Hago la redireccion con el nuevo valor
        addParamForRedirection(column, value);
        filterRedirectionNewParam();
        // Hago que se cierre el mini modal de busqueda
        confirm();
      }

      if (search_value.value[column]) {
        formRef.value
          .validate()
          .then(() => {
            addParamForRedirection(column, value);
            filterRedirectionNewParam();
            confirm();
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
      if (value == undefined || value == '') {
        formRef.value.clearValidate();
      }
    };

    // Limpiar columna
    const handleColumnReset = (column, resetConfirm) => {
      filterRedirectionResetColumn(column);
      resetConfirm();
      delete filter_values.value[column];
      if (column == 'companyRut') {
        formRef.value.resetFields();
        formRef.value.clearValidate();
      }

      if (column === 'state') {
        states_selection.value = [];
      }

      if (column === 'companyId') {
        selected_companies.value = [];
      }
    };

    // Busqueda por estado
    const stateTicketSearchFilter = (states, confirm) => {
      addParamForRedirection('state', states.join(','));
      filterRedirectionNewParam();
      confirm();
    };

    // * Selector por compania
    /*
    Como el selector de compania tiene que ver en conjunto a la parte del dashboard donde el usuario tiene que selecccionar que
    companias van a aparecer filtradas antes de entrar, va a tener una logica dintinta a las demas selecciones y va a ser de este estilo
    */
    const getCompaniesList = async () => {
      try {
        store.dispatch(FETCH_ALL_COMPANIES);
      } catch (error) {
        console.log(error);
      }
    };

    const selected_companies = ref([]);
    const companies = computed(() => {
      return store.getters[GET_ALL_COMPANIES].map((obj) => {
        return {
          value: obj.id,
          label: obj.businessName
        };
      });
    });

    // * Filtro de fecha desde afuera
    watch(
      () => props.dates,
      (new_val) => {
        if (new_val[0] != undefined && new_val[1] != undefined) {
          addParamForRedirection('startDate', new_val[0]);
          addParamForRedirection('endDate', new_val[1]);
        } else {
          deleteParamForRedirection('startDate');
          deleteParamForRedirection('endDate');
        }

        filterRedirectionNewParam();
      }
    );

    watch(filter_values.value, (value) => {
      if (value.companyRut == '') {
        formRef.value.clearValidate();
      }
    });

    // * Recarga de pagina, obtencion de los valores de la url a filtros ### (4) ###
    onMounted(() => {
      // Voy a obtener la lista de companias para el selector mismo
      getCompaniesList();

      // Al momento de cargar la pagina, voy a ver que parametros vienen, con eso voy a dejar los valores de cada uno de los filtros realizados ahi
      const keys = Object.keys(route.query);

      keys.forEach((element) => {
        filter_values.value[element] = route.query[element]; // Le paso valor al los inputs para que muestre que es lo que busco anteriormente
        search_value.value[element] = route.query[element]; // Le paso valor al objeto de la busqueda en caso de hacer otra consulta

        // en caso de que el filtro que venga sea 'State', voy a dejar selecciadas las casillas checkbox
        if (element === 'state') {
          states_selection.value.push(
            ...route.query[element].split(',').map((e) => parseInt(e))
          );
        }

        // en caso de que el filtro que venga sea 'companyId', voy a dejar selecciadas las casillas checkbox
        if (element === 'companyId') {
          selected_companies.value.push(
            ...route.query[element].split(',').map((e) => parseInt(e))
          );
        }
      });
    });
    const checkRut = async (rule, value) => {
      if (!value) {
        return;
      }
      if (!rutHelper.validateRut(value)) {
        return Promise.reject('RUT no válido');
      }
      return Promise.resolve();
    };
    const rules = {
      companyRut: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    return {
      filter_values,
      search_value,
      table_columns,
      table_data,
      handleColumnReset,
      handleSearch,
      filterRedirectionNewParam,
      pagination_props,
      changeCurrentPage,
      states_selection,
      states_options,
      stateTicketSearchFilter,
      loading_data,
      companies,
      selected_companies,
      ticket_type,
      formRef,
      validError,
      formatRuts,
      rules,
    };
  }
};
</script>
